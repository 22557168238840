<template>
  <v-chart
    class="echarts"
    :option="option"
    autoresize
    :theme="theme"
    ref="graph"
  />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { HeatmapChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  VisualMapComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import DataService from "../lib/DataService";
import debounce from "lodash/debounce";

use([
  CanvasRenderer,
  HeatmapChart,
  GridComponent,
  TooltipComponent,
  VisualMapComponent,
]);

export default {
  name: "HeatmapChart",
  components: {
    VChart,
  },
  props: {
    categoriesOb: Array,
    categoriesRe: Array,
    resources: Array,
    mainTheme: String,
  },
  async mounted() {
    this.setTheme();
    this.uniqueCategories = await DataService.uniqueCategories();
    this.setGraph();
  },
  methods: {
    setTheme() {
      if (this.mainTheme == "dark") {
        this.theme = "dark";
      } else {
        this.theme = "light";
      }
      this.$forceUpdate();
    },
    setGraph() {
      const series = [];
      this.$refs.graph.clear();
      this.option.yAxis.data = this.categoriesOb.map(
        (element) => element + " (obverse)"
      );
      this.option.xAxis.data = this.categoriesRe.map(
        (element) => element + " (reverse)"
      );
      let that = this;
      series.push({
        type: "heatmap",
        data: [],
        name: `Overlap`,
        tooltip: {
          trigger: "item", // Trigger tooltip on item (data point) hover
          position: "top",
          formatter: function (params) {
            // params.value[0] and params.value[1] are the x and y indices respectively
            // params.value[2] is the actual value for the heatmap cell
            const xAxisLabel = that.option.xAxis.data[params.value[0]]; // Get x-axis label using x index
            const yAxisLabel = that.option.yAxis.data[params.value[1]]; // Get y-axis label using y index
            return `${yAxisLabel}, ${xAxisLabel}: ${params.value[2]}`;
          },
        },
        label: {
          show: true,
          formatter: function (params) {
            return params.value[2];
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      });
      let occurences = [];
      this.resources.forEach((element) => {
        if (
          element.properties.categories_ob.length > 0 &&
          element.properties.categories_re.length > 0
        ) {
          occurences.push({
            obverse: element.properties.categories_ob,
            reverse: element.properties.categories_re,
          });
        }
      });

      // Step 2: Initialize frequency counter
      let frequencyCounter = {};

      // Counting frequencies of category occurrences
      this.resources.forEach((resource) => {
        const obverse = resource.properties.categories_ob[0];
        const reverse = resource.properties.categories_re[0];
        if (obverse && reverse) {
          const key = `${obverse}-${reverse}`;
          frequencyCounter[key] = (frequencyCounter[key] || 0) + 1;
        }
      });

      // Generating heatmap data based on axis labels ordering
      let heatmapData = Object.entries(frequencyCounter).map(([key, value]) => {
        const [obverse, reverse] = key.split("-");
        const x = this.categoriesRe.indexOf(reverse); // xAxis uses categoriesRe
        const y = this.categoriesOb.indexOf(obverse); // yAxis uses categoriesOb
        return [x, y, value];
      });

      // Updating series data and re-rendering the chart
      series[0].data = heatmapData;
      this.option.series = series;
      this.$refs.graph.setOption(this.option, true); // Ensur
    },
  },
  watch: {
    mainTheme() {
      this.setTheme();
    },
    categoriesOb() {
      this.setGraph();
    },
    categoriesRe() {
      this.setGraph();
    },
    resources: {
      handler: debounce(function () {
        this.setGraph();
      }, 100), // Adjust debounce time based on actual UX requirements
      deep: true,
    },
  },
  data() {
    return {
      heatmapData: [],
      uniqueCategories: [],
      theme: "light",
      option: {
        tooltip: {
          position: "bottom",
        },
        grid: {
          left: "28%",
          top: "20%",
          bottom: "35%",
          right: "28%",
        },
        xAxis: {
          type: "category",
          data: [],
          splitArea: {
            show: true,
          },
        },
        yAxis: {
          type: "category",
          data: [],
          splitArea: {
            show: true,
          },
        },
        visualMap: {
          min: 0,
          max: 10,
          calculable: true,
          orient: "horizontal",
          left: "center",
          bottom: "0%",
        },
        series: [
          {
            name: "Punch Card",
            type: "heatmap",
            data: [],
            label: {
              show: true,
              formatter: function (params) {
                return params.value[2];
              },
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.echarts {
  width: 100%;
  height: 200px;
}
</style>