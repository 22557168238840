<template>
  <div>
    <div id="ui">
      <Visualizer />
    </div>
  </div>
</template>

<script>
import Visualizer from "./components/Visualizer.vue";

export default {
  name: "App",
  components: {
    Visualizer,
  },
  mounted() {},
};
</script>

<style></style>
