<template>
  <div class="w-full lg:columns-1 sm:columns-1 gap-3 grid-flow-col">
    <div class="shadow-md px-4 pt-3 pb-3">
      <div
        class="text-center dark:text-white text-base"
        v-if="features.length > 0 && location != ''"
      >
        <i v-if="features.length > 1"
          ><span v-if="textFilter.length > 1"
            >{{ orderedFeatures.length }}/</span
          >{{ features.length }} items around {{ location }} (modern
          location)</i
        ><i v-else>One item around {{ location }} (modern location)</i>
      </div>
      <input
        v-if="features.length > 1"
        type="text"
        ref="textFilterInput"
        placeholder="Filter items..."
        v-model="textFilter"
        @input="scrollTop"
        style="height: 36px"
        class="mt-2 text-search w-full p-1 pl-2 border border-fancy-grey pt-2 pb-2 rounded dark:bg-dark-charcoal dark:border dark:border-white dark:text-white"
      />
    </div>
    <div
      class="overflow-y-auto h-screen absolute overscroll-none px-4 pb-8"
      style="padding-bottom: 105px; padding-top: 20px"
      ref="featuresContainer"
    >
      <div
        v-for="feature in orderedFeatures"
        :key="feature.properties.coin_type_name"
        class="text-gray-800 rounded text-sm py-2 px-4 pt-5 border border-neutral-200 shadow-md mb-6 break-inside-avoid pt-4 bg-neutral-100 dark:bg-light-charcoal dark:text-white dark:border-light-charcoal"
      >
        <ResourceImage :resource="feature" :base-size="'md'" />
        <div class="mt-4 mb-2">
          <span class="text-lg"
            ><b>{{ feature.properties.coins_title }}</b
            >&nbsp;
            <a
              target="_blank"
              :href="feature.properties.coin"
              title="View Source"
              class="underline text-mpiwg-green hover:text-mpiwg-light-green dark:text-white"
              ><i
                class="fa fa-external-link fa-sm"
                aria-hidden="true"
              ></i></a></span
          ><br />
          <span
            class="break-words"
            v-html="feature.properties.description_ob"
          ></span>
          <br />
          <b>Mint Location:</b>&nbsp;<span
            v-html="feature.properties.mint_location"
          ></span
          ><br />
          <span
            v-if="
              feature.properties.start_date != feature.properties.start_date
            "
          >
            <b>Dates:</b>&nbsp;<span
              v-html="feature.properties.start_date"
            ></span
            >/<span v-html="feature.properties.end_date"></span>
          </span>
          <span v-else
            ><b>Dates:</b>&nbsp;<span
              v-html="feature.properties.start_date"
            ></span
            >/<span v-html="feature.properties.end_date"></span>
          </span>
          <span v-if="feature.properties.categories_ob.length">
            <br />
            <b>Categories (Obverse):</b>&nbsp;{{
              feature.properties.categories_ob.join(", ")
            }}
          </span>
          <span v-if="feature.properties.categories_re.length">
            <br />
            <b>Categories (Reverse):</b>&nbsp;{{
              feature.properties.categories_re.join(", ")
            }}
          </span>
          <br />
          <b>Material:</b>&nbsp;<span
            v-html="feature.properties.material"
          ></span
          ><br />
          <br />
          <b>Description (Obverse):</b>&nbsp;<span
            v-html="feature.properties.description_obverse_en"
          ></span
          ><br />
          <b>Description (Reverse)</b>&nbsp;<span
            v-html="feature.properties.description_reverse_en"
          ></span
          ><br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResourceImage from "./ResourceImage.vue";
import axios from "axios";
import _ from "lodash";

export default {
  name: "SideShow",
  components: { ResourceImage },
  props: {
    features: Array,
  },
  data() {
    return {
      location: "",
      textFilter: "",
    };
  },
  watch: {
    async features() {
      this.textFilter = "";
      this.location = "";
      const coordinates = this.features[0].geometry.coordinates;
      const response = await axios.get(
        `https://secure.geonames.org/findNearbyPlaceNameJSON?lat=${coordinates[1]}&lng=${coordinates[0]}&username=mpiwg_heavens`
      );
      const place = response.data.geonames[0];
      if (place) {
        //this.location = `${place.adminName1 || place.name}, ${
        //  place.countryName
        //}`;
        this.location = `${place.adminName1 || place.name}, ${
          place.countryName
        }`;
      } else {
        this.location = "unknown location";
      }
      if (this.features.length > 1) {
        this.$nextTick(() => {
          this.$refs.textFilterInput.focus();
        });
      }
      this.scrollTop();
    },
  },
  computed: {
    orderedFeatures() {
      const regex = new RegExp(this.textFilter, "i"); // Compiled once

      return _.orderBy(this.features, "properties.start_date", "asc").filter(
        (feature) => {
          // Check if title is non-null before proceeding
          if (feature.properties.coins_title) {
            // Array of properties to test against the regex
            const propertiesToCheck = [
              "coins_title",
              "description_obverse_en",
              "material",
              "description_reverse_en",
            ];

            // Check if regex matches any of the specified properties
            return propertiesToCheck.some((property) =>
              regex.test(feature.properties[property])
            );
          }
          return false;
        }
      );
    },
  },
  methods: {
    scrollTop() {
      this.$refs.featuresContainer.scrollTo(0, 0);
    },
    rsUrl(ref) {
      return `https://maxplanck.resourcespace.com/?r=${ref}`;
    },
  },
};
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
