<template>
  <div>
    <div class="w-full flex h-full place-content-center mb-6" v-if="!loaded">
      <i class="fa-solid fa-circle-notch fa-spin fa-xl mt-6 min-h-full"></i>
    </div>
    <img
      ref="pic"
      :src="url"
      class="w-full rounded shadow-md"
      :class="{ 'cursor-pointer': !error }"
      @error="errorImage"
      v-show="loaded"
    />
  </div>
</template>

<script>
import Viewer from "viewerjs";

export default {
  name: "ResourceImage",
  components: {},
  data() {
    return {
      loaded: false,
      url: "/images/spinning_wheel.gif",
      url_large: "",
      error: false,
      viewer: null,
    };
  },
  props: {
    resource: Object,
    baseSize: String,
  },
  mounted() {
    this.getLink(this.resource);
  },
  watch: {
    resource() {
      this.url = "/images/spinning_wheel.gif";
      this.getLink(this.resource);
    },
    url_large(values) {
      const image = this.$refs.pic;
      this.viewer = new Viewer(image, {
        url() {
          return values;
        },
        navbar: false,
        title: false,
        toolbar: {
          zoomIn: true,
          zoomOut: true,
          oneToOne: true,
          reset: true,
          prev: false,
          play: {
            show: false,
            size: "large",
          },
          next: false,
          rotateLeft: true,
          rotateRight: true,
          flipHorizontal: true,
          flipVertical: true,
        },
      });
    },
  },
  methods: {
    errorImage(event) {
      this.error = true;
      event.target.src = "/images/placeholder.png";
      if (this.viewer) {
        this.viewer.destroy();
      }
    },
    async getLink(resource) {
      if (this.baseSize == "md") {
        this.url =
          "/images/coins/merged/" +
          resource.properties.coin_type_name +
          "_merged.jpg";
      } else {
        this.url =
          "/images/coins/merged_thumb/" +
          resource.properties.coin_type_name +
          "_merged_thumb.jpg";
      }
      this.url_large =
        "/images/coins/merged/" +
        resource.properties.coin_type_name +
        "_merged.jpg";
      this.loaded = true;
    },
  },
};
</script>

<style scoped>
@import "~viewerjs/dist/viewer.css";
</style>
