<template>
  <v-chart
    class="chart dark:bg-slate-800"
    :option="option"
    autoresize
    :theme="theme"
    id="charts-container"
  />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import "../themes/roma.js";
import "../themes/vintage.js";
import "../themes/dark.js";
import debounce from "lodash/debounce";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  name: "Charts",
  components: {
    VChart,
  },
  props: {
    resources: Array,
    categoriesOb: Array,
    categoriesRe: Array,
    mainTheme: String,
  },
  methods: {
    setTheme() {
      this.theme = this.mainTheme === "dark" ? "dark" : "light";
    },
    calculateValues() {
      const processData = (categories, suffix) =>
        categories.map((element) => {
          const count = this.resources.filter((el) =>
            el.properties[`categories_${suffix}`].includes(element)
          ).length;
          return {
            name: `${element} (${suffix}verse)`,
            value: count,
          };
        });

      this.option.series[0].data = [
        ...processData(this.categoriesOb, "ob"),
        ...processData(this.categoriesRe, "re"),
      ];

      this.option.legend.data = this.option.series[0].data.map(
        (item) => item.name
      );
    },
  },
  watch: {
    mainTheme: "setTheme",
    categoriesOb: "calculateValues",
    categoriesRe: "calculateValues",
    resources: {
      handler() {
        this.debouncedCalculateValues();
      },
      deep: true,
    },
  },
  created() {
    this.debouncedCalculateValues = debounce(this.calculateValues, 100);
  },
  mounted() {
    this.setTheme();
    this.calculateValues();
  },
  data() {
    return {
      theme: "light",
      option: {
        animationDuration: 1000,
        animationEasing: "cubicInOut",
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
          position: function (point, params, dom, rect, size) {
            return [point[0] + 10, point[1] - size.contentSize[1] / 2];
          },
        },
        legend: {
          orient: "horizontal",
          left: "left",
          data: [],
        },
        series: [
          {
            name: "Category",
            type: "pie",
            radius: "60%",
            center: ["50%", "60%"],
            data: [],
            animation: true,
            itemStyle: {
              borderRadius: 4,
              borderWidth: 2,
            },
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            emphasis: {
              scale: true,
              scaleSize: 10,
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.echarts {
  width: 100%;
  height: 300px;
  /* or e.g. 400px */
}
</style>
