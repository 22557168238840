import Vue from "vue";

class DataService {
  constructor() { }
  async resources() {
    const response = await Vue.axios.get("/json/coins_geojson.json");
    return response.data;
  }

  async categoryTreeObverse() {
    const response = await Vue.axios.get("/json/categories_ob.json");
    let tree = response.data;
    tree.forEach(obj => {
      obj.label += ` (${obj.count})`;
    });
    return tree;
  }

  async categoryTreeReverse() {
    const response = await Vue.axios.get("/json/categories_re.json");
    let tree = response.data;
    tree.forEach(obj => {
      obj.label += ` (${obj.count})`;
    });
    return tree;
  }

  async categoryIndexRe() {
    const response = await Vue.axios.get("/json/intersections_re.json");
    return response.data;
  }

  async categoryIndexOb() {
    const response = await Vue.axios.get("/json/intersections_ob.json");
    return response.data;
  }

  async categoryDistributionRe() {
    const response = await Vue.axios.get("/json/category_re_distribution.json");
    return response.data;
  }


  async categoryDistributionOb() {
    const response = await Vue.axios.get("/json/category_ob_distribution.json");
    return response.data;
  }

  async heatmapData() {
    const response = await Vue.axios.get("/json/heatmap_data.json");
    return response.data;
  }

  async uniqueCategories() {
    const response = await Vue.axios.get("/json/unique_categories.json");
    return response.data;
  }


}

export default new DataService();
