<template>
  <div class="mt-0">
    <div
      class="shadow table-wrp w-full rounded-lg block overflow-auto text-black"
      :class="{ 'h-280': !mapVisible, 'h-180': mapVisible }"
      ref="tableWrap"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <table class="leading-normal w-full">
        <thead class="sticky top-0 z-3">
          <th
            class="px-6 py-3 border-b-2 min-w-42 bg-neutral-200 text-center text-xs font-semibold text-neutral-600 uppercase tracking-wider w-32 dark:border-light-charcoal dark:text-white dark:bg-light-charcoal"
          >
            <span class="font-semibold text-gray-900 dark:text-white">{{
              sortedResources.length
            }}</span>
            Coins
          </th>
          <th
            class="max-xl:hidden px-3 py-3 border-b-2 bg-gray-200 w-52 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider dark:border-light-charcoal dark:text-white dark:bg-light-charcoal"
          >
            <span v-if="resources.length > 0">
              <a class="sort-link" @click="sort('properties.name')">name</a
              ><span
                v-if="
                  currentSort == 'properties.name' && currentSortDir == 'desc'
                "
                >&#9660;</span
              >
              <span
                v-if="
                  currentSort == 'properties.name' && currentSortDir == 'asc'
                "
                >&#9650;</span
              >
            </span>
          </th>
          <th
            class="max-xl:hidden px-3 py-3 border-b-2 bg-gray-200 w-32 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider dark:border-light-charcoal dark:text-white dark:bg-light-charcoal"
          >
            <span v-if="resources.length > 0">
              <a class="sort-link" @click="sort('properties.start_date')">
                start date</a
              ><span
                v-if="
                  currentSort == 'properties.start_date' &&
                  currentSortDir == 'desc'
                "
                >&#9660;</span
              >
              <span
                v-if="
                  currentSort == 'properties.start_date' &&
                  currentSortDir == 'asc'
                "
                >&#9650;</span
              >
            </span>
          </th>
          <th
            class="max-xl:hidden px-3 py-3 border-b-2 bg-gray-200 text-left w-32 text-xs font-semibold text-gray-600 uppercase tracking-wider dark:border-light-charcoal dark:text-white dark:bg-light-charcoal"
          >
            <span v-if="resources.length > 0">
              <a class="sort-link" @click="sort('properties.end_date')"
                >end date</a
              ><span
                v-if="
                  currentSort == 'properties.end_date' &&
                  currentSortDir == 'desc'
                "
                >&#9660;</span
              >
              <span
                v-if="
                  currentSort == 'properties.end_date' &&
                  currentSortDir == 'asc'
                "
                >&#9650;</span
              >
            </span>
          </th>
          <th
            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider dark:border-light-charcoal dark:text-white dark:bg-light-charcoal"
          >
            <span v-if="resources.length > 0">Astral Depictions</span>
          </th>
          <th
            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider dark:border-light-charcoal dark:text-white dark:bg-light-charcoal"
          >
            <span v-if="resources.length > 0">Description</span>
          </th>
          <th
            class="mpx-1 py-3 border-b-2 w-96 whitespace-nowrap border-gray-200 break-inside-avoid bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider dark:border-light-charcoal dark:text-white dark:bg-light-charcoal"
          >
            <span v-if="resources.length > 0">
              <button
                @click="exportCsv()"
                style="float: right"
                class="inline-flex items-center py-2 px-4 bg-white hover:bg-neutral-100 text-xs py-2 px-4 border border-gray-400 rounded shadow mr-2 dark:border-light-charcoal dark:hover:bg-dark-charcoal dark:bg-charcoal"
              >
                <i class="fa-solid fa-download"></i>
              </button>
              <button
                style="float: right"
                class="dark:disabled:bg-neutral-700 dark:disabled:text-neutral-600 dark:disabled:hover:text-neutral-600 disabled:bg-neutral-100 disabled:text-neutral-400 disabled:border-neutral-300 disabled:hover:text-neutral-400 inline-flex items-center py-2 px-4 bg-white hover:bg-neutral-100 text-xs py-2 px-4 border border-gray-400 rounded shadow mr-2 dark:border-light-charcoal dark:hover:bg-dark-charcoal dark:bg-charcoal"
                @click="next"
                :disabled="
                  indexStart + paginated.length >= sortedResources.length
                "
              >
                <i class="fa-solid fa-arrow-right"></i>
              </button>
              <!-- Buttons -->
              <button
                style="float: right"
                class="dark:disabled:bg-neutral-700 dark:disabled:text-neutral-600 dark:disabled:hover:text-neutral-600 disabled:bg-neutral-100 disabled:text-neutral-400 disabled:border-neutral-300 disabled:hover:text-neutral-400 inline-flex items-center py-2 px-4 bg-white hover:bg-neutral-100 text-xs py-2 px-4 border border-gray-400 rounded shadow mr-2 dark:border-light-charcoal dark:hover:bg-dark-charcoal dark:bg-charcoal"
                @click="prev"
                :disabled="indexStart <= 0"
              >
                <i class="fa-solid fa-arrow-left"></i>
              </button>
              <span class="py-2 px-2" style="float: right">
                <span class="font-semibold text-gray-900 dark:text-white">{{
                  indexStart + 1
                }}</span>
                to
                <span class="font-semibold text-gray-900 dark:text-white">{{
                  indexStart + paginated.length
                }}</span>
              </span>
              <!--<input
              type="text"
              ref="textFilterInput"
              placeholder="Filter items..."
              v-model="textFilter"
              @input="filterItems"
              style="height:32px;float:right"
              class="p-1 pl-2 border border-gray-400 pt-2 pb-2 rounded dark:bg-dark-charcoal
    dark:border dark:border-white dark:text-white mr-2"
            />-->
            </span>
          </th>
        </thead>
        <tbody
          class="dark:bg-lighter-charcoal overflow-y-auto"
          v-if="resources.length > 0"
        >
          <tr
            v-for="resource in paginated"
            :key="resource.properties.coin_type_name"
            class="px-5 py-5 border-b border-gray-200 dark:bg-light-charcoal text-sm odd:dark:bg-dark-charcoal odd:bg-white dark:border-light-charcoal dark:text-white max-h-fit"
          >
            <td class="p-3">
              <ResourceImage :resource="resource" :base-size="'thm'" />
            </td>
            <td class="p-3 max-xl:hidden">
              <span
                class="underline cursor-pointer"
                @click="setFeature(resource)"
                >{{ resource.properties.coins_title }}</span
              >
              &nbsp;
              <a
                target="_blank"
                :href="resource.properties.coin"
                title="View Source"
                class="underline text-mpiwg-green hover:text-mpiwg-light-green dark:text-white"
                ><i class="fa fa-external-link fa-sm" aria-hidden="true"></i
              ></a>
            </td>
            <td class="p-3 max-xl:hidden">
              {{ resource.properties.start_date }}
            </td>
            <td class="p-3 max-xl:hidden">
              {{ resource.properties.end_date }}
            </td>
            <td class="p-3">
              <div
                v-if="
                  resource.properties.categories_ob.length > 0 ||
                  resource.properties.categories_re.length > 0
                "
              >
                <p v-if="resource.properties.categories_ob.length > 0">
                  <b>Obverse: </b>
                  {{ resource.properties.categories_ob.join(", ") }}
                </p>
                <p v-if="resource.properties.categories_re.length > 0">
                  <b>Reverse: </b>
                  {{ resource.properties.categories_re.join(", ") }}
                </p>
              </div>
              <div v-else>
                <p><i>No depiction found</i></p>
              </div>
            </td>
            <td class="p-3" colspan="2">
              <p>
                <b>Obverse: </b>
                {{ resource.properties.description_obverse_en }}
              </p>
              <p>
                <b>Reverse: </b>
                {{ resource.properties.description_reverse_en }}
              </p>
            </td>
          </tr>
        </tbody>
        <tbody class="dark:bg-lighter-charcoal" v-else>
          <tr class="text-center">
            <td colspan="7" class="p-8">
              <i>No results</i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ResourceImage from "./ResourceImage.vue";
import _ from "lodash";
import he from "he";
import debounce from "lodash/debounce";

export default {
  name: "Grid",
  components: { ResourceImage },
  async mounted() {
    this.sort("properties.start_date");
  },
  props: {
    resources: Array,
    currentFeatures: Array,
    mapVisible: Boolean,
  },
  data() {
    return {
      hover: false,
      current: 1,
      pageSize: 20,
      textFilter: "",
      currentSort: "properties.start_date",
      currentSortDir: "desc",
      sortedResources: this.resources.map((o) => ({ ...o })),
    };
  },
  created() {
    window.addEventListener("keydown", (e) => {
      if (this.hover) {
        if (e.key == "ArrowLeft" && !(this.indexStart <= 0)) {
          this.prev();
        }
        if (
          e.key == "ArrowRight" &&
          !(
            this.indexStart + this.paginated.length >=
            this.sortedResources.length
          )
        ) {
          this.next();
        }
      }
    });
  },
  computed: {
    indexStart() {
      return (this.current - 1) * this.pageSize;
    },
    indexEnd() {
      return this.indexStart + this.pageSize;
    },
    paginated() {
      return this.sortedResources.slice(this.indexStart, this.indexEnd);
    },
  },
  watch: {
    resources() {
      this.currentSortDir = "desc";
      this.sort("properties.start_date");
      this.current = 1;
    },
    watch: {
      resources: {
        handler: debounce(function (newResources) {
          if (newResources !== this.resources) {
            this.updateMapResources(newResources);
          }
        }, 100), // Adjust debounce time based on actual UX requirements
        deep: true,
      },
    },
  },
  methods: {
    //filterItems() {
    //  this.currentSortDir = "asc";
    //  this.sort("properties.start_date");
    //},
    setFeature(resource) {
      this.$parent.currentFeatures = [resource];
      this.$parent.toggleSlideover();
    },
    scrollTop() {
      this.$refs.tableWrap.scrollTo(0, 0);
    },
    sort(s) {
      this.current = 1;
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
      this.sortedResources = _.orderBy(
        this.resources,
        [this.currentSort],
        [this.currentSortDir]
      );
      this.scrollTop();
    },
    exportCsv() {
      const filename = "heavens_export.csv";
      //CSV
      const csvRows = [];
      const keys = Object.keys(this.sortedResources[0].properties);
      csvRows.push(keys.join(";"));
      for (const row of this.sortedResources) {
        const values = keys.map((header) => {
          const val = row.properties[header];
          if (header == "category_ids") {
            return `"${this.displayCats(val)}"`;
          } else {
            if (val === null) {
              return '""';
            } else {
              if (typeof val == "string") {
                return `"${he
                  .decode(val)
                  .replace(/<[^>]*>?/gm, "")
                  .replace(/"/g, '""')}"`;
              } else {
                return `"${val}"`;
              }
            }
          }
        });
        csvRows.push(values.join(";"));
      }

      let data = csvRows.join("\n");
      data = _.replace(data, "category_ids", "categories");

      //BOM
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      //Blob
      const blob = new Blob([bom, data], { type: "text/csv" });

      //IE
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const url = (window.URL || window.webkitURL).createObjectURL(blob);
        const download = document.createElement("a");
        download.href = url;
        download.download = filename;
        download.click();
        (window.URL || window.webkitURL).revokeObjectURL(url);
      }
    },
    prev() {
      this.scrollTop();
      this.current--;
    },
    next() {
      this.scrollTop();
      this.current++;
    },
  },
};
</script>

<style scoped>
.sort-link {
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap;
}
</style>
