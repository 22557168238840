<template>
  <div>
    <div class="grid grid-cols-12 w-full max-h-fit min-w-[900px]">
      <!-- Main modal -->
      <div
        id="aboutModal"
        data-modal-backdrop="static"
        tabindex="-1"
        aria-hidden="true"
        class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full"
      >
        <div class="relative w-full h-full max-w-4xl md:h-auto">
          <!-- Modal content -->
          <div
            class="relative bg-white rounded-lg shadow dark:bg-dark-charcoal"
          >
            <!-- Modal header -->
            <div
              class="flex items-start justify-between p-3 pl-6 pt-4 border-b rounded-t bg-mpiwg-green dark:bg-charcoal dark:border-light-charcoal"
            >
              <h3 class="text-xl text-white dark:text-white">
                <span v-if="modalContent == 'about'">About this Platform</span>
                <span v-else>Help</span>
              </h3>
              <button
                type="button"
                class="text-white bg-transparent hover:text-mpiwg-light-green rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:text-white dark:text-neutral-400"
                id="closeButton"
              >
                <svg
                  class="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <!-- Modal body -->
            <div class="p-6 space-y-6" v-if="modalContent == 'about'">
              <!--<p class="text-gray-500 dark:text-white">
                <iframe v-if="modalVisible" width="560" height="315" src="https://www.youtube.com/embed/uVFKBN1-P6U"
                  title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen class="w-full" ref="YTVideo"></iframe>
              </p>-->
              <div class="w-full flex justify-center items-center">
                <img
                  v-if="mainTheme == 'light'"
                  src="images/MPI_WG_wide_E_green.png"
                />
                <img v-else src="images/MPI_WG_wide_E_neg.png" />
              </div>
              <p class="text-neutral-900 dark:text-white">
                Welcome to the <i>Heavens</i> data visualization platform,
                designed by the
                <a
                  href="https://www.mpiwg-berlin.mpg.de/research/projects/visualizations-of-the-heavens-working-group"
                  target="_blank"
                  class="underline"
                  >Visualizing the Heavens</a
                >
                group and Research IT at the
                <a
                  href="https://www.mpiwg-berlin.mpg.de/"
                  class="underline"
                  target="_blank"
                  >Max Planck Institute for the History of Science</a
                >. This tool lets you explore a collection of more than 13,000
                coins, each depicting various celestial themes.
              </p>
              <p class="text-neutral-900 dark:text-white">
                Our platform is designed for anyone interested in how the Romans
                represented the heavens on their currency. You can filter
                through the coins by time period, region, and the specific
                astral symbols they bear, making it easier to find the
                information you need. Each coin in our collection is documented
                with clear descriptions and detailed images, making this a great
                resource for both educational purposes and scholarly research.
              </p>
              <p class="text-neutral-900 dark:text-white">
                Whether you're studying history, numismatics, or are just
                curious about ancient cultures, this tool provides a
                straightforward way to access and learn from these historical
                artifacts. Explore the connection between ancient astronomy and
                currency by diving into our collection of Roman coins. Discover
                the rich imagery of the skies as seen by the Romans and learn
                about the cultural significance behind each piece.
              </p>
            </div>
            <div
              v-else
              class="flex px-8 pt-4 pb-8 text-neutral-900 dark:text-white"
            >
              <div class="w-full md:w-1/2 pr-4">
                <h1
                  class="text-xl font-semibold mt-4 mb-2 text-mpiwg-green dark:text-white"
                >
                  Filters
                </h1>
                <p class="mb-2">
                  <strong>Depiction Filters (Obverse/Reverse)</strong><br />
                  Select coins by the images or symbols depicted. Click on the
                  filter, choose one or more depictions, and explore the
                  matching coins. <br />You can combine obverse and reverse
                  filters for more precise results, and use the <i>"any"</i> and
                  <i>"all at the same time"</i> toggle to refine your search
                  even more.
                </p>
                <p class="mb-2">
                  <strong>Text Search (Descriptions/Titles)</strong><br />
                  Enter keywords to find coins with specific terms in their
                  descriptions or titles.
                </p>
                <p class="mb-2">
                  <strong>Timeline Filter</strong><br />
                  Use the timeline filter situated under the map to view coins
                  from selected periods by dragging or clicking along the
                  timeline bar.
                </p>
                <div class="bg-gray-100 p-4 rounded-lg dark:bg-charcoal mt-4">
                  <h2 class="text-sm font-semibold mb-2">
                    <i class="fa-regular fa-lightbulb mr-1"></i>Tips
                  </h2>
                  <p class="text-sm">
                    Combine filters for precise results. For example, use both
                    depiction and timeline filters to identify specific eras
                    with particular depictions. Clear filters individually to
                    adjust your search criteria.
                  </p>
                </div>
              </div>
              <div class="w-full md:w-1/2 pl-4">
                <h1
                  class="text-xl font-semibold mt-4 mb-2 text-mpiwg-green dark:text-white"
                >
                  Map
                </h1>
                <p class="mb-2">
                  <strong>Map Styles</strong><br />
                  Change the map's appearance for better clarity or preference.
                  Choose from the style options like
                  <i>"topographic - color"</i> or <i>"dark - greyscale"</i> at
                  the top left of the map.
                </p>
                <p class="mb-2">
                  <strong>Overlays</strong><br />
                  You can use the overlay selector located under the map style
                  selector to add layers that display the boundaries of the
                  Roman empire or the geopolitical divisions of the world during
                  specific historical periods.
                </p>
                <p class="mb-2">
                  <strong>Map Markers</strong><br />
                  Click on any red marker to see detailed information about that
                  location’s coins. The markers represent concentrations of
                  coins, with numbers indicating the quantity at each location.
                </p>
                <p class="mb-2">
                  <strong>Geo Filter</strong><br />
                  Use the geo filter
                  <i class="fa-solid fa-vector-square"></i> to draw a bounding
                  box around a location. You can remove the box you drew by
                  first clicking on it to select it, and clicking the bin icon
                  <i class="fa-solid fa-trash-can"></i>. Please note that you
                  can create as many bounding boxes as you wish.
                </p>
                <div class="bg-gray-100 p-4 rounded-lg dark:bg-charcoal mt-4">
                  <h2 class="text-sm font-semibold mb-2">
                    <i class="fa-regular fa-lightbulb mr-1"></i>Tips
                  </h2>
                  <p class="text-sm">
                    Utilize map styles to match your visual preference or to
                    enhance the visibility of markers. Try out the various modes
                    available at the top right of the map to explore various
                    ways of visualizing the data.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-span-12 text-left shadow grid grid-cols-12 dark:shadow-none"
      >
        <a
          href="/"
          class="col-span-3 max-lg:hidden min-lg:block p-2 place-items-center pt-2 dark:bg-dark-charcoal"
        >
          <img
            class="h-6 inline ml-3 dark:invert"
            src="images/mpiwg_logo_single.png"
          />
          <span class="inline ml-2 pt-2 mt-2 text-mpiwg-green dark:text-white"
            >Visualizing the Heavens</span
          >
        </a>
        <div
          class="max-lg:col-span-6 lg:col-span-5 bg-mpiwg-green place-items-left dark:bg-lighter-charcoal"
        >
          <div
            class="text-xs pt-3 ml-4 text-white font-bold dark:text-dark-charcoal"
          >
            <span v-if="!loaded"
              ><i class="fa-solid fa-circle-notch fa-sm fa-spin mr-1"></i
              >Loading...</span
            >
            <span v-else>Showing {{ filteredResources.length }} coins</span>
          </div>
        </div>
        <div
          class="max-lg:col-span-6 lg:col-span-4 place-items-end text-right p-2 bg-mpiwg-green pr-0 dark:bg-lighter-charcoal w-full"
        >
          <button
            class="text-white text-xs border-r pr-2 pl-2 dark:border-charcoal dark:text-charcoal hover:text-mpiwg-light-green"
            @click="toggleTheme"
          >
            <i
              class="fa-solid fa-sm"
              :class="mainTheme == 'dark' ? 'fa-sun' : 'fa-moon'"
              aria-hidden="true"
            ></i>
          </button>
          <button
            id="aboutButton"
            class="text-white text-xs border-r pr-2 pl-2 dark:border-charcoal dark:text-charcoal hover:text-mpiwg-light-green"
          >
            about
          </button>
          <button
            id="helpButton"
            class="text-white text-xs pr-2 pl-2 dark:border-charcoal dark:text-charcoal dark:hover:underline hover:text-mpiwg-light-green mr-2"
          >
            help
          </button>
        </div>
      </div>
      <div
        class="col-span-3 p-4 min-lg:block max-lg:col-span-4 row-span-2 w-full pt-6 min-w-[250px]"
      >
        <div>
          <label class="dark:text-white mb-4 text-sm"
            >Astral Depictions: Obverse<i
              class="fa-solid fa-circle-info cursor-help ml-2 text-neutral-400 dark:hover:text-neutral-200 hover:text-neutral-600"
              data-tooltip-target="tooltip-default"
              data-tooltip-placement="right"
            ></i>
            <div
              id="tooltip-default"
              role="tooltip"
              class="inline-block w-80 absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-neutral-500 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 dark:bg-neutral-900"
            >
              <b>Depiction Filter</b> <br />Use these two drop down menus to
              filter coins featuring depictions of the heavens you are
              interested in. The "any" option filter coins that feature any of
              the selected depictions.<br />
              The "all at the same time" option will show coins that feature
              these depictions at the same time.
            </div>
          </label>
          <treeselect
            :disabled="!loaded"
            :disable-branch-nodes="true"
            search-nested
            v-model="selectedCategoriesObverse"
            :multiple="true"
            :options="optionsOb"
            :auto-focus="true"
            placeholder="Select categories..."
            class="mt-2"
          />
        </div>
        <br />
        <div>
          <label class="dark:text-white mb-4 text-sm"
            >Astral Depictions: Reverse<!--<i
              class="fa-solid fa-circle-info cursor-help ml-2 text-neutral-400 dark:hover:text-neutral-200 hover:text-neutral-600"
              data-tooltip-target="tooltip-default" data-tooltip-placement="right"></i>
            <div id="tooltip-default" role="tooltip"
              class="inline-block w-80 absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-neutral-500 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 dark:bg-neutral-900">
              <b>Filter by category</b> <br />Select the "or" option to filter
              resources that belong to any of the selected categories.<br />
              The "and" option will filter resources that only belong to each of
              the selected categories.
            </div>-->
          </label>
          <treeselect
            :disabled="!loaded"
            :disable-branch-nodes="true"
            search-nested
            v-model="selectedCategoriesReverse"
            :multiple="true"
            :options="optionsRe"
            :auto-focus="true"
            placeholder="Select categories..."
            class="mt-2"
          />
        </div>

        <div class="and-or text-xs align-middle">
          <label for="inclusion2" class="align-middle"
            ><input
              :disabled="!loaded"
              type="radio"
              id="inclusion2"
              name="inclusion"
              value="or"
              v-model="andOr"
              @change="updateResources"
              class="align-middle"
            />
            any</label
          >
          <label for="inclusion1" class="align-middle"
            ><input
              :disabled="!loaded"
              type="radio"
              id="inclusion1"
              name="inclusion"
              value="and"
              v-model="andOr"
              @change="updateResources"
              class="align-middle"
            />
            all at the same time</label
          >
        </div>
        <div class="mt-5">
          <label class="dark:text-white mb-4"
            >Search<i
              class="fa-solid fa-circle-info ml-2 cursor-help text-neutral-400 dark:hover:text-neutral-200 hover:text-neutral-600"
              data-tooltip-target="tooltip-text-filter"
              data-tooltip-placement="right"
            ></i>
            <div
              id="tooltip-text-filter"
              role="tooltip"
              class="inline-block w-80 absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-neutral-500 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-neutral-900"
            >
              <b>Text Search</b> <br />Search the coins that cointain a specific
              text in their title or description.
            </div>
          </label>
          <input
            :disabled="!loaded"
            type="text"
            placeholder="Search text..."
            v-model="textSearch"
            @input="updateSearch"
            @focus="$event.target.select()"
            style="height: 36px"
            class="mt-2 text-search w-full p-1 pl-2 border border-fancy-grey pt-2 pb-2 rounded dark:bg-dark-charcoal dark:border dark:border-white dark:text-white"
          />
        </div>
        <br />
        <Charts
          style="z-index: 6"
          :categoriesOb="selectedCategoriesObverse"
          :categoriesRe="selectedCategoriesReverse"
          :resources="filteredResources"
          :mainTheme="mainTheme"
          v-if="
            filteredResources.length > 0 &&
            selectedCategoriesObverse.length +
              selectedCategoriesReverse.length >
              1
          "
        />
        <GraphDiagram
          style="margin-top: -10px"
          :categoriesOb="selectedCategoriesObverse"
          :categoriesRe="selectedCategoriesReverse"
          :resources="filteredResources"
          :mainTheme="mainTheme"
          :key="mainTheme"
          v-if="
            (filteredResources.length > 0 &&
              selectedCategoriesObverse.length > 0 &&
              selectedCategoriesReverse.length > 1) ||
            (selectedCategoriesObverse.length > 1 &&
              selectedCategoriesReverse.length > 0)
          "
        />
      </div>
      <div
        id="slideover-container"
        class="w-full h-full fixed inset-0 invisible z-50"
      >
        <div
          @click="toggleSlideover"
          id="slideover-bg"
          class="w-full h-full duration-500 ease-out transition-all inset-0 absolute bg-gray-900 opacity-0 dark:bg-charcoal"
        ></div>
        <div
          id="slideover"
          class="w-4/12 bg-white h-full absolute right-0 duration-300 ease-out transition-all translate-x-full dark:bg-dark-charcoal dark:border-l dark:border-charcoal"
        >
          <div
            @click="toggleSlideover"
            class="fixed cursor-pointer text-mpiwg-green top-0 w-8 h-8 flex items-center justify-center right-0 dark:text-neutral-400 dark:hover:text-white hover:text-mpiwg-light-green"
          >
            <i class="fa-solid fa-xmark fa-xl"></i>
          </div>
          <SideShow :features="currentFeatures" />
        </div>
      </div>
      <div
        id="fs-map"
        class="max-lg:col-span-8 col-span-9 bg-neutral-200 dark:bg-dark-charcoal min-w-[600px]"
        :class="{ 'max-h-0': !mapVisible }"
      >
        <Map
          :mapVisible="mapVisible"
          :resources="filteredResources"
          :categories="selectedCategoriesObverse"
          :mainTheme="mainTheme"
          @draw="setPolygons"
          @clicked="setFeatures"
          @hexClicked="setFeaturesFromHex"
          :loaded="loaded"
          @playSlider="toggleTimer()"
          @resetSlider="resetTimer()"
          :class="{ invisible: !mapVisible }"
        />
        <div
          class="bg-mpiwg-light-brown pt-4 text-white border-t border-slate border-l border-b border-gray pb-14 dark:bg-charcoal dark:border-0"
        >
          <div id="time-selector" style="margin: auto" class="px-20">
            <div
              class="container pt-0 mx-0 pb-2 min-w-full flex flex-col items-center"
              style="margin-top: 5px"
            >
              <button
                class="text-neutral-700 dark:text-neutral-200 inline-flex items-center py-0 px-4 pt-0 bg-white hover:bg-neutral-100 text-xs border border-gray-400 rounded shadow mr-2 dark:border-light-charcoal dark:hover:bg-dark-charcoal dark:bg-light-charcoal"
                @click="toggleMap()"
              >
                <i v-if="mapVisible" class="fa-solid fa-caret-up"></i>
                <i v-else class="fa-solid fa-caret-down"></i>
              </button>
            </div>
            <div>
              <div
                id="time-selector"
                class="w-[880px] m-auto max-xl:w-[650px] max-lg:w-[400px]"
              >
                <TimeDistribution
                  :mainTheme="mainTheme"
                  :categoriesOb="selectedCategoriesObverse"
                  :categoriesRe="selectedCategoriesReverse"
                  class="w-full;"
                  v-if="
                    selectedCategoriesObverse.length +
                      selectedCategoriesReverse.length >
                    0
                  "
                />
                <VueSlider
                  v-model="rangeValues"
                  :min="-1000"
                  :max="1000"
                  :enable-cross="false"
                  :tooltip="'always'"
                  :tooltip-placement="'bottom'"
                  :adsorb="true"
                  :interval="20"
                  :min-range="200"
                  ref="slider"
                  style="margin-left: 50px; margin-right: 50px"
                >
                </VueSlider>
                <div style="margin-top: -19px; margin-left: -5px">
                  <div>
                    <button
                      type="button"
                      class="text-mpiwg-green mr-2 drop-shadow-md dark:text-white"
                      :class="playButtonIcon"
                      @click="toggleTimer()"
                    ></button>
                    <button
                      type="button"
                      class="text-mpiwg-green fa-solid fa-arrows-left-right-to-line drop-shadow-md dark:text-white"
                      @click="resetTimer()"
                    ></button>
                  </div>
                </div>

                <Heatmap
                  style="width: 100%"
                  :categoriesOb="selectedCategoriesObverse"
                  :categoriesRe="selectedCategoriesReverse"
                  :resources="filteredResources"
                  :mainTheme="mainTheme"
                  v-if="
                    !mapVisible &&
                    ((selectedCategoriesObverse.length > 0 &&
                      selectedCategoriesReverse.length >= 2) ||
                      (selectedCategoriesObverse.length >= 2 &&
                        selectedCategoriesReverse.length > 0))
                  "
                />
              </div>
            </div>
          </div>
          <div class="p-6 pt-8 mt-4">
            <Grid :resources="filteredResources" :mapVisible="mapVisible" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueSlider from "vue-slider-component";
import { Modal, initTooltips } from "flowbite";

import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Charts from "./Charts.vue";
import GraphDiagram from "./GraphDiagram.vue";
import Heatmap from "./Heatmap.vue";
import TimeDistribution from "./TimeDistribution.vue";
import SideShow from "./SideShow.vue";
import Grid from "./Grid.vue";
import Map from "./Map.vue";
import * as utils from "../lib/Utilities";
import DataService from "../lib/DataService";
import debounce from "lodash/debounce";

export default {
  name: "Visualizer",
  components: {
    Charts,
    GraphDiagram,
    Heatmap,
    VueSlider,
    Treeselect,
    TimeDistribution,
    SideShow,
    Map,
    Grid,
  },
  async mounted() {
    initTooltips();
    const $buttonElement = document.querySelector("#aboutButton");
    const $helpButtonElement = document.querySelector("#helpButton");
    const $closeButton = document.querySelector("#closeButton");
    const $modalElement = document.querySelector("#aboutModal");

    if ($modalElement) {
      const modal = new Modal($modalElement, {
        backdropClasses:
          "bg-light-charcoal bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40",
        onHide: () => {
          document.querySelector("body > div[modal-backdrop]")?.remove();
          this.modalVisible = false;
        },
        onShow: () => {
          this.modalVisible = true;
        },
      });
      $buttonElement.addEventListener("click", () => {
        this.modalContent = "about";
        modal.show();
      });
      $helpButtonElement.addEventListener("click", () => {
        this.modalContent = "help";
        modal.show();
      });
      $closeButton.addEventListener("click", () => {
        modal.hide();
      });
      if (!localStorage.alreadyVisited) {
        modal.show();
        localStorage.alreadyVisited = true;
      }
    }

    this.mainTheme = localStorage.theme;
    if (!this.mainTheme) {
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        this.mainTheme = "dark";
        document.documentElement.classList.add("dark");
      } else {
        this.mainTheme = "light";
        document.documentElement.classList.remove("dark");
      }
    }
    if (this.mainTheme == "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }

    const resourcesGeoJson = await DataService.resources();

    //this.resources = resourcesGeoJson.features.filter(
    //  (x) => x.properties.workflow_status == "active"
    //);
    this.resources = resourcesGeoJson.features;
    this.optionsOb = await DataService.categoryTreeObverse();
    this.optionsRe = await DataService.categoryTreeReverse();
    this.categoryIndexObverse = await DataService.categoryIndexOb();
    this.categoryIndexReverse = await DataService.categoryIndexRe();
    this.updateResources();
    this.loaded = true;
  },
  data() {
    return {
      modalContent: "about",
      mainTheme: "light",
      mapVisible: true,
      modalVisible: true,
      andOr: "or",
      textSearch: "",
      geofuzzFilter: null,
      geofuzzFilterValues: [],
      polygons: null,
      currentFeatures: [],
      toggleCluster: false,
      isRunning: false,
      interval: null,
      time: 0,
      playButtonIcon: "fa-solid fa-play",
      clusteringButtonText: "(off)",
      drawButtonText: "draw",
      optionsOb: [],
      optionsRe: [],
      rangeValues: [-1000, 1000],
      resources: [],
      selectedCategoriesObverse: [],
      selectedCategoriesReverse: [],
      categoryIndexReverse: [],
      categoryIndexObverse: [],
      filteredResources: [],
      loaded: false,
    };
  },
  watch: {
    rangeValues: {
      handler: debounce(function () {
        this.updateResources();
      }, 100), // Adjust debounce time based on actual UX requirements
      deep: true,
    },
    selectedCategoriesObverse() {
      this.updateCategoriesReverse();
      this.updateResources();
    },
    selectedCategoriesReverse() {
      this.updateCategoriesObverse();
      this.updateResources();
    },
  },
  methods: {
    async updateSearch() {
      const debouncedFunction = debounce(() => {
        this.updateResources();
      }, 200);
      debouncedFunction();
    },
    async updateCategoriesObverse() {
      this.optionsOb = await DataService.categoryTreeReverse();
      if (this.selectedCategoriesReverse.length > 0) {
        let whiteListedCategories = [];
        this.selectedCategoriesReverse.forEach((c) => {
          whiteListedCategories.push(...this.categoryIndexReverse[c]);
        });
        whiteListedCategories = [...new Set(whiteListedCategories)];
        let filteredOptions = this.optionsOb.filter((option) => {
          return whiteListedCategories.includes(option.id);
        });
        this.optionsOb = filteredOptions;
      } else {
        this.optionsOb = await DataService.categoryTreeObverse();
      }
    },

    async updateCategoriesReverse() {
      this.optionsRe = await DataService.categoryTreeReverse();
      if (this.selectedCategoriesObverse.length > 0) {
        let whiteListedCategories = [];
        this.selectedCategoriesObverse.forEach((c) => {
          whiteListedCategories.push(...this.categoryIndexObverse[c]);
        });
        whiteListedCategories = [...new Set(whiteListedCategories)];
        let filteredOptions = this.optionsRe.filter((option) => {
          return whiteListedCategories.includes(option.id);
        });
        this.optionsRe = filteredOptions;
      } else {
        this.optionsRe = await DataService.categoryTreeReverse();
      }
    },
    toggleMap() {
      this.mapVisible = !this.mapVisible;
      window.scrollTo({
        top: 0, // Scroll to the top of the window
        left: 0, // Not necessary for scrolling to the top, but included for completeness
        behavior: "smooth", // Optional: this makes the scroll smooth
      });
    },
    capitalize(str) {
      if (!str) return "";
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    toggleTheme() {
      if (this.mainTheme == "light") {
        this.mainTheme = "dark";
        document.documentElement.classList.add("dark");
      } else {
        this.mainTheme = "light";
        document.documentElement.classList.remove("dark");
      }
      localStorage.theme = this.mainTheme;
    },
    toggleSlideover() {
      document
        .getElementById("slideover-container")
        .classList.toggle("invisible");
      document.getElementById("slideover-bg").classList.toggle("opacity-0");
      document.getElementById("slideover-bg").classList.toggle("opacity-50");
      document.getElementById("slideover").classList.toggle("translate-x-full");
      document.getElementById("slideover").scroll({
        top: 0,
      });
    },
    setFeatures(features) {
      this.currentFeatures = features;
      this.toggleSlideover();
    },
    setFeaturesFromHex(featureIds) {
      this.currentFeatures = this.filteredResources.filter((x) =>
        featureIds.includes(x.properties.ref)
      );
      this.toggleSlideover();
    },
    setPolygons(polygons) {
      this.polygons = polygons;
      this.updateResources();
    },
    resetTimer() {
      if (this.isRunning) {
        this.toggleTimer();
      }
      this.rangeValues = [-1000, 1000];
    },
    toggleTimer() {
      if (this.isRunning) {
        this.playButtonIcon = "fa-solid fa-play";
        clearInterval(this.interval);
      } else {
        this.playButtonIcon = "fa-solid fa-pause";
        this.interval = setInterval(this.incrementTime, 250);
      }
      this.isRunning = !this.isRunning;
    },
    incrementTime() {
      this.time = parseInt(this.time) + 1;
      let newRangeValues = this.rangeValues;

      if (newRangeValues[1] < 1000) {
        newRangeValues[1] = newRangeValues[1] + 10;
      } else {
        //newRangeValues[1] = 2500;
      }

      if (newRangeValues[0] < newRangeValues[1] - 200) {
        newRangeValues[0] = newRangeValues[0] + 10;
      } else {
        newRangeValues[0] = newRangeValues[1] - 200;
        this.toggleTimer();
      }

      this.updateResources();
      this.$refs.slider.setValue(newRangeValues);
    },
    updateResources() {
      // Precompile regex for performance improvement and check if there's a search term
      const hasSearchTerm = this.textSearch.trim().length > 0;
      const regex = hasSearchTerm ? new RegExp(this.textSearch, "i") : null;

      // Filter resources by polygons first
      let polyFilteredResources = utils.filterPointsFromPoly(
        this.resources,
        this.polygons
      );

      // Single pass filter operation
      this.filteredResources = polyFilteredResources.filter((el) => {
        // Filter based on coordinates, coin existence, date range, and text search
        if (
          el.geometry.coordinates.length === 0 ||
          el.properties.coin == null ||
          el.properties.start_date <= this.rangeValues[0] ||
          el.properties.end_date >= this.rangeValues[1]
        ) {
          return false;
        }

        // Filter based on text search
        if (
          hasSearchTerm &&
          !(
            regex.test(el.properties.description_obverse_en) ||
            regex.test(el.properties.description_reverse_en) ||
            regex.test(el.properties.material) ||
            regex.test(el.properties.coins_title)
          )
        ) {
          return false;
        }

        // Filter based on both sides of the coin if both obverse and reverse are chosen
        if (
          this.selectedCategoriesObverse.length > 0 &&
          this.selectedCategoriesReverse.length > 0
        ) {
          const doubleMatch =
            this.andOr === "and"
              ? this.selectedCategoriesObverse.every((r) =>
                  el.properties.categories_ob.includes(r)
                ) &&
                this.selectedCategoriesReverse.every((r) =>
                  el.properties.categories_re.includes(r)
                )
              : this.selectedCategoriesObverse.some((r) =>
                  el.properties.categories_ob.includes(r)
                ) ||
                this.selectedCategoriesReverse.some((r) =>
                  el.properties.categories_re.includes(r)
                );

          // const allitems = this.selectedCategoriesObverse.concat(this.selectedCategoriesReverse)
          // allitems.every(r => el.properties.categories_ob.includes(r) && el.properties.categories_re.includes(r)):
          // allitems.some(r => el.properties.categories_ob.includes(r) || el.properties.categories_re.includes(r))
          return doubleMatch;
        }

        // Filter based on selected obverse categories
        if (this.selectedCategoriesObverse.length > 0) {
          const obverseMatch =
            this.andOr === "and"
              ? this.selectedCategoriesObverse.every((r) =>
                  el.properties.categories_ob.includes(r)
                )
              : this.selectedCategoriesObverse.some((r) =>
                  el.properties.categories_ob.includes(r)
                );
          return obverseMatch;
        }

        if (this.selectedCategoriesReverse.length > 0) {
          const reverseMatch =
            this.andOr === "and"
              ? this.selectedCategoriesReverse.every((r) =>
                  el.properties.categories_re.includes(r)
                )
              : this.selectedCategoriesReverse.some((r) =>
                  el.properties.categories_re.includes(r)
                );
          return reverseMatch;
        }

        return true;
      });
    },
  },
};
</script>
<style>
@import "~vue-slider-component/theme/default.css";

#fs-map {
  position: relative;
}

.time-selector-map-hidden {
  margin-top: 120px !important;
  margin-bottom: 0px !important;
}

.play-bar {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.and-or {
  margin-top: 10px;
}

.text-search::placeholder {
  color: #bdbdbd;
}

.vue-slider-process {
  background-color: #006464 !important;
}

.vue-slider-dot-tooltip {
  border: 1px solid #006464;
  border-radius: 5px;
}

.vue-slider-dot-tooltip-inner {
  background-color: white !important;
  border-color: #006464 !important;
  cursor: grab;
  color: #006464;
}

.vue-treeselect__multi-value-label,
.vue-treeselect__icon,
.vue-treeselect__multi-value-item {
  color: #006464 !important;
  background-color: #d4e0e0 !important;
}

.dark * .mapboxgl-ctrl {
  background-color: #d4d4d4;
  filter: invert(100%);
}

.dark * .mapboxgl-ctrl select {
  outline: none !important;
}

.dark * .mapboxgl-ctrl-group {
  border-color: #333;
}

.dark * .vue-slider-process {
  background-color: lightgray !important;
}

.dark * .vue-slider-rail {
  /* border: 1px solid #515151 !important; */
  background-color: #414141;
}

.dark * .vue-slider-dot-tooltip {
  border: 1px solid white;
  border-radius: 5px;
}

.dark * .vue-slider-dot-tooltip-inner {
  background-color: #333 !important;
  border-color: white !important;
  color: white;
}

.dark * .vue-treeselect__control,
.dark * .vue-treeselect--branch-nodes-disabled {
  background-color: #414141 !important;
  color: white !important;
}

.dark * .vue-treeselect__multi-value-label,
.dark * .vue-treeselect__icon,
.dark * .vue-treeselect__multi-value-item {
  background-color: #333 !important;
  color: white !important;
}

.dark * .vue-treeselect__menu:hover,
.dark * .vue-treeselect__option--highlight {
  color: #333;
}

.dark * .vue-treeselect__menu {
  color: white !important;
  background-color: #545454 !important;
}

.modal-backdrop.show {
  display: none !important;
}

.dark * .vue-treeselect__multi-value-item:hover,
.dark * .vue-treeselect__option--selected:hover {
  color: #333 !important;
}

.dark * .vue-treeselect__control-arrow {
  fill: lightgray !important;
}

.dark * .vue-treeselect__control-arrow:hover {
  fill: white !important;
}

.dark * .text-search::placeholder {
  color: #bdbdbd;
}

.dark * .and-or {
  margin-top: 10px;
  color: white;
}

select {
  background-image: url("data:image/svg+xml;utf8,<svg fill='lightgrey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 5px;
  fill: "#d3d3d3" !important;
}

.dark * select {
  background-image: url("data:image/svg+xml;utf8,<svg fill='lightgrey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 5px;
  fill: "#d3d3d3" !important;
}
</style>
