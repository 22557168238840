import * as turf from "@turf/turf";
import { point, destination } from '@turf/turf';
import { scaleLog } from "d3-scale";

export const getWeight = (geo_fuzz) => {
  switch (geo_fuzz) {
    case "city":
      return 6;
    case "province":
      return 12;
    case "island":
      return 6;
    case "place":
      return 36;
    case "country":
      return 60;
    case "land mass":
      return 70;
    default:
      return 1; // Default case handles 'null' or any other unexpected values.
  }
};

export function distributePoints(data, baseWeight = 1, maxOffsetForMaxWeight = 250) {
  let newPoints = [];

  for (let item of data) {
    const { coordinates, weight } = item;
    const numClones = Math.round(weight / baseWeight);
    const weightRatio = weight / 48;
    const dynamicMaxOffset = weightRatio * weightRatio * maxOffsetForMaxWeight;

    // Cache creation of turf point object
    const startPoint = point(coordinates);

    for (let i = 0; i < numClones; i++) {
      const bearing = Math.random() * 360;
      const distance = Math.sqrt(Math.random()) * dynamicMaxOffset;

      const newPoint = destination(startPoint, distance, bearing);
      newPoints.push({
        coordinates: newPoint.geometry.coordinates,
        weight: baseWeight, // Each cloned point has the base weight
      });
    }
  }

  return newPoints;
}

export const chartColorPalette = {
  light: [
    "#E01F54",
    "#001852",
    "#f5e8c8",
    "#b8d2c7",
    "#c6b38e",
    "#a4d8c2",
    "#f3d999",
    "#d3758f",
    "#dcc392",
    "#2e4783",
    "#82b6e9",
    "#ff6347",
    "#a092f1",
    "#0a915d",
    "#eaf889",
    "#6699FF",
    "#ff6666",
    "#3cb371",
    "#d5b158",
    "#38b6b6",
  ],
  roma:
    ['#E01F54', '#001852', '#f5e8c8', '#b8d2c7', '#c6b38e',
      '#a4d8c2', '#f3d999', '#d3758f', '#dcc392', '#2e4783',
      '#82b6e9', '#ff6347', '#a092f1', '#0a915d', '#eaf889',
      '#6699FF', '#ff6666', '#3cb371', '#d5b158', '#38b6b6'
    ],
  dark: [
    "#dd6b66",
    "#759aa0",
    "#e69d87",
    "#8dc1a9",
    "#ea7e53",
    "#eedd78",
    "#73a373",
    "#73b9bc",
    "#7289ab",
    "#91ca8c",
    "#f49f42",
  ],
};

export const colorPalette = [
  "#E01F54",
  "#001852",
  "#f5e8c8",
  "#b8d2c7",
  "#c6b38e",
  "#a4d8c2",
  "#f3d999",
  "#d3758f",
  "#dcc392",
  "#2e4783",
  "#82b6e9",
  "#ff6347",
  "#a092f1",
  "#0a915d",
  "#eaf889",
  "#6699FF",
  "#ff6666",
  "#3cb371",
  "#d5b158",
  "#38b6b6",
];

export const mapPalette = [
  "#E01F54",
  "#f5e8c8",
  "#b8d2c7",
  "#c6b38e",
  "#a4d8c2",
  //"#f3d999",
  "#d3758f",
  "#dcc392",
  "#82b6e9",
  "#ff6347",
  "#0a915d",
  "#eaf889",
  "#ff6666",
  "#3cb371",
  "#d5b158",
  "#38b6b6",
  "#f9f9f9",
  "#e072a4",
  "#F78764",
  "#DA4167",
];

//const rangePalette = palette.map((x) => x.rgb);

export const colorScale = scaleLog()
  .domain([1, 10, 30, 150])
  .range([
    [255, 255, 178],
    [254, 204, 92],
    [253, 141, 60],
    [227, 26, 28],
  ]);

export const host = "https://maxplanck.resourcespace.com/api";

export function random(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function filterPointsFromPoly(resources, polys) {
  //rebuild featurecollection
  const collection = {
    type: "FeatureCollection",
    name: "resources",
    features: resources,
  };
  if (polys && polys.features.length > 0) {
    const filteredPoints = turf.pointsWithinPolygon(collection, polys);
    resources = filteredPoints.features;
  }
  return resources;
}

export function colorPolygons(polygonFeatureCollection) {
  const polys = polygonFeatureCollection.features;
  const len = polys.length;
  const colorLen = mapPalette.length;
  const coloredPolys = [];
  for (let i = 0; i < len; i++) {
    const modulo = i % colorLen;
    polys[i].properties.color = mapPalette[modulo];
    const existingPoly = polys.find(
      (x) => x.properties.NAME && x.properties.NAME == polys[i].properties.NAME
    );
    if (existingPoly) {
      polys[i].properties.color = existingPoly.properties.color;
    }
    coloredPolys.push(polys[i]);
  }
  const featureCollection = turf.featureCollection(coloredPolys);
  return featureCollection;
}

export function getCentroids(polygonFeatureCollection) {
  const centroids = [];
  const polys = polygonFeatureCollection.features;
  const len = polys.length;
  for (let i = 0; i < len; i++) {
    const poly = turf.centroid(polys[i]);
    poly.properties.name = polys[i].properties.NAME;
    poly.properties.text_size = Math.floor(
      Math.log(turf.area(polys[i]) / 100000000) + 4
    );
    if (poly.properties.text_size < 10) {
      poly.properties.text_size = 10;
    }
    if (poly.properties.text_size > 14) {
      poly.properties.text_size = 14;
    }
    if (poly.properties.name == null) {
      poly.properties.name = "";
    }
    centroids.push(poly);
  }
  const featureCollection = turf.featureCollection(centroids);
  return featureCollection;
}

export function hash(string) {
  const utf8 = new TextEncoder().encode(string);
  return crypto.subtle.digest("SHA-256", utf8).then((hashBuffer) => {
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, "0"))
      .join("");
    return hashHex;
  });
}

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function takeScreenshot(map) {
  return new Promise(function (resolve) {
    map.once("render", function () {
      resolve(
        map.getCanvas().toDataURL().replace("image/png", "image/octet-stream")
      );
    });
    /* trigger render */
    map.setBearing(map.getBearing());
  });
}

export const saveContent = (fileContents, fileName) => {
  const link = document.createElement("a");
  link.download = fileName;
  link.href = fileContents;
  link.click();
};

export const objectToCSVRow = (dataObject) => {
  var dataArray = new Array();
  for (var o in dataObject) {
    var innerValue = dataObject[o] === null ? "" : dataObject[o].toString();
    var result = innerValue.replace(/"/g, '""');
    result = '"' + result + '"';
    dataArray.push(result);
  }
  return dataArray.join(" ") + "\r\n";
};
