<template>
  <div class="map-wrap dark:border dark:border-light-charcoal">
    <div class="map" ref="mapContainer">
      <div v-if="!mapLoaded" class="p-4 justify-self-center w-16 m-auto mt-60">
        <img src="/images/loading-gif.webp" class="dark:invert-75" />
      </div>
    </div>
  </div>
</template>

<script>
import { Map, NavigationControl, FullscreenControl } from "maplibre-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as utils from "../lib/Utilities.js";
import { MapboxLayer } from "@deck.gl/mapbox";
import { H3HexagonLayer } from "@deck.gl/geo-layers";
import { HeatmapLayer } from "@deck.gl/aggregation-layers";
import * as turf from "@turf/turf";
import { latLngToCell } from "h3-js";
import {
  LayerToggle,
  BaseMapSelect,
  OverlaySelect,
  SavePic,
  //Player,
} from "../lib/CustomMapControls.js";
import RomanEmpireLayer from "../lib/map_layers/RomanEmpire.js";
import ResourcePointsLayer from "../lib/map_layers/ResourcePoints.js";
import HistoricalBordersLayer from "../lib/map_layers/HistoricalBorders";
import RasterLayer from "../lib/map_layers/Raster";
import debounce from "lodash/debounce";

export default {
  name: "Map",
  components: {},
  data() {
    let baseMaps = [
      {
        url: "https://api.maptiler.com/maps/e991c003-4309-412a-844d-f5b097c17a9a/style.json?key=tJbEqiQiVsTbL0CKfbmw",
        name: "dark - greyscale",
      },
      {
        url: "https://api.maptiler.com/maps/56a879e1-eeef-414f-bb64-ad2538420d0f/style.json?key=tJbEqiQiVsTbL0CKfbmw",
        name: "topographic - color",
      },
      {
        url: "https://api.maptiler.com/maps/b6b0f090-b935-4149-bc99-70112fdd40b3/style.json?key=tJbEqiQiVsTbL0CKfbmw",
        name: "simple - greyscale",
      },
      {
        url: "https://api.maptiler.com/maps/basic-v2/style.json?key=tJbEqiQiVsTbL0CKfbmw",
        name: "modern - color",
      },
      {
        url: "https://api.maptiler.com/maps/01c4ce23-b7a6-4d9a-9302-bc7b515ef8e0/style.json?key=tJbEqiQiVsTbL0CKfbmw",
        name: "modern - greyscale",
      },
    ];
    return {
      eventsSet: false,
      resourcePointsLayer: null,
      currentBaseMapIndex: 0,
      rasterLayers: [],
      rasters: [
        //{
        //  name: "China 1911",
        //  url: "https://chmap.mpiwg-berlin.mpg.de/t/China1911/{z}/{x}/{y}.png",
        //},
        //{
        //  name: "China 1820",
        //  url:
        //    "https://logart.mpiwg-berlin.mpg.de/ts/re.php?l=China_1820_prov_pgn-{z}-{x}-{y}-png",
        //},
      ],
      historicalLayers: [],
      map: null,
      hexVisible: false,
      heatVisible: false,
      hexLoaded: false,
      heatLoaded: false,
      clustersVisible: true,
      romanEmpireVisible: false,
      features: [],
      mapLoaded: false,
      overlayLayers: [
        { id: "RomanEmpire", name: "roman empire borders" },
        {
          id: "HistoricalBorders",
          // 100, 200, 300, 400, 500, 600
          years: [
            "bc700",
            "bc500",
            "bc400",
            "bc323",
            "bc300",
            "bc200",
            "bc100",
            "bc1",
            "100",
            "200",
            "300",
            "400",
            "500",
            "600",
            "700",
            "800",
            "900",
            "1000",
          ],
        },
      ],
      baseMaps: baseMaps,
    };
  },
  props: {
    mapVisible: Boolean,
    resources: Array,
    categories: Array,
    mainTheme: String,
    loaded: Boolean,
  },
  async created() {
    await this.setHistoricalMaps();
  },
  async mounted() {},
  methods: {
    async initialize() {
      const initialState = {
        lng: 30.753,
        lat: 20.6844,
        zoom: 2.2,
        maxZoom: 10,
        minZoom: 1.5,
      };
      await this.setRasterLayers();
      if (this.mainTheme == "dark") {
        this.currentBaseMapIndex = 0;
      } else {
        this.currentBaseMapIndex = 1;
      }
      //couldn't find a better solution to wait for the historicalmaps to load...
      //await utils.delay(this.historicalLayers.length * 250);
      //this.historicalLayers.find((x) => x.year == "1500").visible = true;
      //this.romanEmpireVisible = true;
      this.map = await new Map({
        container: this.$refs.mapContainer,
        style: this.baseMaps[this.currentBaseMapIndex].url,
        center: [initialState.lng, initialState.lat],
        zoom: initialState.zoom,
        maxZoom: initialState.maxZoom,
        minZoom: initialState.minZoom,
        antialias: true,
        attributionControl: true,
        //preserveDrawingBuffer: true,
        //pitch: 0,
      });
      this.map.on("load", async () => {
        this.setControls();
        this.mapLoaded = true;
      });

      //this.map.on("zoomend", async () => {
      //  if (this.heatVisible) {
      //    this.addHeatLayer();
      //  }
      //});

      this.map.on("styledata", async () => {
        if (!this.map.getSource("currentResources")) {
          this.historicalLayers.map((x) => x.addSource(this.map));
          this.features = this.resources;
          this.resourcePointsLayer = new ResourcePointsLayer();
          this.resourcePointsLayer.addSource(this.map, this.features);
          RomanEmpireLayer.addSource(this.map);
        }
        if (!this.map.getLayer("clusters")) {
          this.setupLayers();
        }
      });
    },
    updateMapResources(newResources) {
      this.features = newResources;
      const featureCollection = turf.featureCollection(newResources);
      const source = this.map.getSource("currentResources");
      if (source) {
        source.setData(featureCollection);
      }

      if (this.hexVisible) {
        this.addHexLayer();
      }
      //if (this.heatVisible) {
      //  this.addHeatLayer();
      //}
    },
    async setRasterLayers() {
      for (const raster of this.rasters) {
        let rasterLayer = new RasterLayer(raster.name, raster.url);
        this.rasterLayers.push(rasterLayer);
      }
    },
    async setHistoricalMaps() {
      const historicalYears = await this.overlayLayers.find(
        (x) => x.id == "HistoricalBorders"
      ).years;

      for (const year of historicalYears) {
        let layer = new HistoricalBordersLayer(year);
        this.historicalLayers.push(layer);
      }
    },
    async setControls() {
      this.map.addControl(new LayerToggle({ component: this }));
      this.map.addControl(new BaseMapSelect({ component: this }), "top-left");
      this.map.addControl(new OverlaySelect({ component: this }), "top-left");

      await this.map.addControl(
        new NavigationControl({ visualizePitch: true }),
        "top-right"
      );
      //this.map.addControl(new Player({ component: this }));
      var draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true,
        },
      });
      this.map.on("draw.create", updateArea);
      this.map.on("draw.delete", updateArea);
      this.map.on("draw.update", updateArea);
      const that = this;
      function updateArea() {
        var drawnData = draw.getAll();
        that.$emit("draw", drawnData);
      }

      this.map.getCanvas().addEventListener("keydown", (e) => {
        const key = e.key;
        if (key === "Backspace") {
          draw.trash();
          var drawnData = draw.getAll();
          that.$emit("draw", drawnData);
        }
      });
      this.map.addControl(draw);
      this.map.addControl(
        new FullscreenControl({ container: document.querySelector("#fs-map") }),
        "bottom-right"
      );
      this.map.addControl(new SavePic(), "top-right");
    },
    async addHexLayer() {
      this.hideHexLayer();
      this.setupHexLayer();
      // https://github.com/visgl/deck.gl/issues/3522
      this.map.__deck.props.getCursor = () => this.map.getCanvas().style.cursor;
    },
    async hideHexLayer() {
      if (this.map.getLayer("hex")) {
        this.map.removeLayer("hex");
      }
    },
    async addHeatLayer() {
      this.hideHeatLayer();
      this.setupHeatLayer();
      // https://github.com/visgl/deck.gl/issues/3522
      this.map.__deck.props.getCursor = () => this.map.getCanvas().style.cursor;
    },
    async hideHeatLayer() {
      if (this.map.getLayer("heat")) {
        this.map.removeLayer("heat");
      }
    },
    async hexData() {
      const hexs = [];
      const len = this.features.length;
      for (let i = 0; i < len; i++) {
        const feature = this.features[i];
        const hex = latLngToCell(
          feature.geometry.coordinates[1],
          feature.geometry.coordinates[0],
          3 // hex radius
        );
        const found = hexs.find((e) => e.hex == hex);
        if (found) {
          found["features"].push(feature.properties.ref);
          found["count"] += 1;
        } else {
          hexs.push({ hex: hex, features: [feature.properties.ref], count: 1 });
        }
      }
      return hexs;
    },
    async heatData() {
      const data = [];
      const len = this.features.length;
      for (let i = 0; i < len; i++) {
        const feature = this.features[i];
        data.push({
          coordinates: [
            parseFloat(feature.geometry.coordinates[0]),
            parseFloat(feature.geometry.coordinates[1]),
          ],
          weight: utils.getWeight(feature.properties.geo_fuzz),
        });
      }
      const expandedData = utils.distributePoints(data);
      return expandedData;
    },
    async setupHexLayer() {
      const that = this;
      const hexLayer = new MapboxLayer({
        id: "hex",
        type: H3HexagonLayer,
        data: this.hexData(),
        opacity: 0.7,
        pickable: false,
        autoHighlight: true,
        wireframe: true,
        highlightColor: [225, 32, 84],
        //onClick: ({ object }) =>
        //  object && that.$emit("hexClicked", object.features),
        getHexagon: (d) => d.hex,
        getElevation: (d) => d.count * 150,
        onHover: (info) => {
          if (info.picked) {
            that.map.__deck.props.getCursor = () => "pointer";
          } else {
            that.map.__deck.props.getCursor = () => "inherit";
          }
        },
        getFillColor: (d) => utils.colorScale(d.count / 2),
        extruded: true,
      });
      this.map.addLayer(hexLayer, "clusters");
      this.hexLoaded = true;
    },
    async setupHeatLayer() {
      const zoomLevel = this.map.getZoom();
      const heatLayer = new MapboxLayer({
        id: "heat",
        type: HeatmapLayer,
        data: this.heatData(),
        getPosition: (d) => d["coordinates"],
        getWeight: (d) => d["weight"],
        aggregation: "SUM",
        radiusPixels: 16 * Math.pow(zoomLevel, 1.8),
        intensity: 0.8,
      });
      this.map.addLayer(heatLayer, "clusters");
      this.heatLoaded = true;
    },
    async setupLayers() {
      this.rasterLayers.map((x) => x.add(this.map));
      RomanEmpireLayer.add(this.map);
      this.historicalLayers.map((x) => x.add(this.map));
      this.resourcePointsLayer.add(this.map, this);

      for (const layer of this.historicalLayers) {
        if (layer.visible) {
          layer.show(this.map);
          RomanEmpireLayer.hide(this.map);
          this.romanEmpireVisible = false;
        } else {
          layer.hide(this.map);
        }
      }

      if (this.romanEmpireVisible) {
        RomanEmpireLayer.show(this.map);
        for (const layer of this.historicalLayers) {
          layer.hide(this.map);
        }
      } else {
        RomanEmpireLayer.hide(this.map);
        this.romanEmpireVisible = false;
      }
      if (this.clustersVisible) {
        this.resourcePointsLayer.show(this.map);
      } else {
        this.resourcePointsLayer.hide(this.map);
      }
      if (this.hexVisible) {
        await utils.delay(1500);
        this.addHexLayer();
      }
      //if (this.heatVisible) {
      //  await utils.delay(1500);
      //  this.addHeatLayer();
      //}
    },
  },
  watch: {
    mapVisible(newVisibility, oldVisibility) {
      if (newVisibility !== oldVisibility) {
        this.map.resize();
      }
    },
    loaded(newValue) {
      if (newValue) {
        this.initialize();
      }
    },
    resources: {
      handler: debounce(function (newResources) {
        if (this.loaded && newResources !== this.features) {
          this.updateMapResources(newResources);
        }
      }, 50), // Adjust debounce time based on actual UX requirements
      deep: true,
    },
  },
};
</script>
<style scoped>
@import "~maplibre-gl/dist/maplibre-gl.css";
@import "~@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

.map-wrap {
  position: relative;
  width: 100%;
  height: 100%;
}

.map {
  width: 100%;
  height: 100%;
}
</style>
