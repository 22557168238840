<template>
  <v-chart
    class="echarts"
    :option="option"
    autoresize
    :theme="mainTheme"
    ref="graph"
  />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { HeatmapChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  VisualMapComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import DataService from "../lib/DataService";
import debounce from "lodash/debounce";

use([
  CanvasRenderer,
  HeatmapChart,
  GridComponent,
  TooltipComponent,
  VisualMapComponent,
]);

export default {
  name: "HeatmapChart",
  components: {
    VChart,
  },
  props: {
    categoriesOb: Array,
    categoriesRe: Array,
    resources: Array,
    mainTheme: String,
  },
  async mounted() {
    this.setTheme();
    this.uniqueCategories = await DataService.uniqueCategories();
    this.setGraph();
  },
  methods: {
    transformDataForECharts(inputData) {
      let nodes = {};
      let links = [];

      inputData.forEach((item) => {
        // Add nodes for obverse items
        item.obverse.forEach((obverseItem) => {
          if (!nodes[obverseItem]) {
            nodes[obverseItem] = { name: obverseItem };
          }
        });

        // Add nodes and links for reverse items
        item.reverse.forEach((reverseItem) => {
          if (!nodes[reverseItem]) {
            nodes[reverseItem] = { name: reverseItem };
          }

          // Assuming each obverse item is linked to each reverse item
          item.obverse.forEach((obverseItem) => {
            links.push({ source: obverseItem, target: reverseItem });
          });
        });
      });

      // Convert nodes object to array
      let nodesArray = Object.values(nodes);

      return {
        nodes: nodesArray,
        links: links,
      };
    },
    setTheme() {
      if (this.mainTheme == "dark") {
        this.mainTheme = "dark";
      } else {
        this.mainTheme = "light";
      }
      this.$forceUpdate();
    },
    setGraph() {
      let nodeDetails = new Map();
      let linkMap = new Map();

      this.resources.forEach((element) => {
        const filteredObverse = element.properties.categories_ob.filter(
          (category) => this.categoriesOb.includes(category)
        );
        const filteredReverse = element.properties.categories_re.filter(
          (category) => this.categoriesRe.includes(category)
        );

        filteredObverse.forEach((obItem) => {
          const obName = `${obItem} (obverse)`;
          if (!nodeDetails.has(obName)) {
            nodeDetails.set(obName, { name: obName, connections: 0 });
          }

          filteredReverse.forEach((reItem) => {
            const reName = `${reItem} (reverse)`;
            if (!nodeDetails.has(reName)) {
              nodeDetails.set(reName, { name: reName, connections: 0 });
            }

            nodeDetails.get(obName).connections++;
            nodeDetails.get(reName).connections++;

            const linkKey = `${obName}->${reName}`;
            linkMap.set(linkKey, (linkMap.get(linkKey) || 0) + 1);
          });
        });
      });

      let obverseColor = "#006464";
      let reverseColor = "#40A0A0";
      if (this.mainTheme == "dark") {
        obverseColor = "#A0A0A0";
        reverseColor = "#656565";
      }
      let seriesData = Array.from(nodeDetails.values())
        .filter((node) => node.connections > 0)
        .map((node) => ({
          name: node.name,
          connections: node.connections,
          symbolSize: Math.min(Math.max(10, node.connections * 1.2), 22),
          itemStyle: {
            color: node.name.includes("(obverse)")
              ? obverseColor
              : reverseColor,
          },
        }));

      let seriesLinks = Array.from(linkMap).map(([key, count]) => {
        const [source, target] = key.split("->");
        return {
          source,
          target,
          lineStyle: {
            width: Math.min(Math.max(count, 1), 5),
            color: obverseColor,
          },
        };
      });
      // Update data directly instead of resetting the entire option
      if (this.$refs.graph) {
        const chart = this.$refs.graph;
        chart.setOption(
          {
            series: [
              {
                data: seriesData,
                links: seriesLinks,
              },
            ],
          },
          { notMerge: false }
        );
      }
    },
  },
  watch: {
    mainTheme() {
      this.setTheme();
      this.setGraph();
    },
    categoriesOb() {
      this.setGraph();
    },
    categoriesRe() {
      this.setGraph();
    },
    resources: {
      handler: debounce(function () {
        this.setGraph();
      }, 100), // Adjust debounce time based on actual UX requirements
      deep: true,
    },
  },
  data() {
    return {
      uniqueCategories: [],
      option: {
        grid: {
          top: "0",
        },
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            if (params.dataType === "edge") {
              return `${params.data.lineStyle.width} coins`;
            }
            return params.data.name;
          },
          position: function (point, params, dom, rect, size) {
            // Calculates position to place tooltip on the right
            // 'point' is the position of the mouse or data item
            // 'size' contains the size of the tooltip
            return [point[0] + 10, point[1] - size.contentSize[1] / 2];
          },
        },
        series: [
          {
            animationDurationUpdate: 500,
            animationEasingUpdate: "quinticInOut",
            type: "graph",
            layout: "circular",
            symbolSize: 10,
            roam: false,
            label: {
              show: true,
              position: "bottom",
              fontSize: 11,
              formatter: "{b}",
            },
            edgeSymbol: ["circle"],
            edgeSymbolSize: [4, 10],
            data: [],
            links: [],
            lineStyle: {
              opacity: 0.9,
              width: 2,
              curveness: 0.3,
              color: "#40A0A0",
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.echarts {
  width: 100%;
  height: 250px;
}
</style>